.app-container {
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-view {
  flex-grow: 25;
}

.app-footer {
  background-color: #A1DBE9;
  height: 60px;
}
