.info-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.info-header {
  background-color: #A1DBE9;
  height: 10%;
  text-align: center;
  justify-content: center;
  font-size: 40px;
  padding: 10px;
}

.info-content {
  font-size: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 140%;
}

.info-id-input-container {
  align-items: center;
  justify-content: center;
}

.info-id-input {
  height: 35px;
  width: 250px;
  position: relative;
  top: -50px;
  left: 125px;
  font-size: 30px;
  padding: 3px 10px;
}

.info-login {
  position: relative;
  top: -30px;
}