
.problem {
  margin: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.problem-title {
  color: blue;
  font-weight: bold;
}

.problem-download-button {
  margin: 3px 0;
  width: 40%;
}

.problem-submit {
  display: flex;
  padding: 3px 0;
}

.problem-submit-language {
  flex-grow: 6;
  margin-right: 5px;
}

.problem-submit-button {
  flex-grow: 4;
  margin-left: 5px;
}

.code-block {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.code-input {
  width: 100%;
  flex-grow: 10;
  resize: none;
}