.rule-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rule-title {
  margin-bottom: 30px;
  font-size: 40px;
}

.rule-brief {
  text-align: center;
  font-size: 25px;
  line-height: 200%;
}

.rule-details-title {
  display: flex;
  justify-content: center;
  font-size: 25px;
  color: red;
  margin: 20px;
}

.rule-details-content {
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  line-height: 120%;
  margin: 20px
}